import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Project } from 'src/app/interfaces/project';
import { RatingResponse } from 'src/app/interfaces/rating-response';
import { LocationService } from 'src/app/services/location.service';
import { RatingService } from 'src/app/services/rating.service';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { SmileyInputComponent } from '../../../smiley-input/smiley-input.component';
import { LoaderComponent } from '../../../loader/loader.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-card-rating',
  templateUrl: './card-rating.component.html',
  standalone: true,
  imports: [
    NgIf,
    LoaderComponent,
    FormsModule,
    ReactiveFormsModule,
    SmileyInputComponent,
    InlineSVGModule,
    TranslateModule,
  ],
})
export class CardRatingComponent implements OnInit {
  @Input() language: string;
  @Input() project: Project;
  @Output() openLink = new EventEmitter<void>();
  firstTime: boolean = true;
  responses: RatingResponse[];
  repetitive: boolean = false;
  sent: boolean = false;
  loading: boolean = true;
  reacted: boolean = false;
  invalid: boolean = false;
  subscription: Subscription;
  public form: FormGroup;
  theme: any;
  @Input() withPadding: boolean = false;

  constructor(
    private fb: FormBuilder,
    private ratingService: RatingService,
    private locationService: LocationService
  ) {
    this.subscription = this.ratingService.saved.subscribe(() => {
      this.sent = true;
      this.invalid = true;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private createForm() {
    this.form = this.fb.group({
      score: [null, Validators.required],
      content: [null],
      theme: ['/api/rating-themes/' + this.theme.id],
    });
  }

  private formChangeSub() {
    this.form.valueChanges.subscribe(async (event: any) => {
      let location: GeolocationPosition | null = null;

      try {
        location = await this.locationService.getCurrentPosition();
      } catch (error) {
        // no location available, but continue
      }

      const ratingResponse: RatingResponse = {
        project: this.project,
        items: [this.form.value],
        locationLat: location === null ? null : location.coords.latitude,
        locationLong: location === null ? null : location.coords.longitude,
      };

      try {
        await this.ratingService.rate(ratingResponse);
        this.reacted = true;
      } catch (error) {
        this.form.get('score').setValue(0, { emitEvent: false });
      }
    });
  }

  ngOnInit() {
    this.theme = this.project.ratingThemes[0];
    this.loadResponses();
    this.createForm();
  }

  open() {
    this.openLink.next();
  }

  async loadResponses() {
    this.responses = await this.ratingService.listByProject(this.project);
    this.firstTime = this.responses.length === 0;
    this.repetitive = this.responses.length > 1;
    const previousResponse = this.responses[0];

    //to-do: improve this part and load once on project load, not per card
    if (
      !this.firstTime &&
      !this.reacted &&
      (this.project.ratingPushedAt == null ||
        moment(previousResponse.createdAt).isSameOrAfter(
          this.project.ratingPushedAt
        ))
    ) {
      if (previousResponse.items.length !== this.project.ratingThemes.length) {
        this.reacted = true;
        this.form.get('score').setValue(previousResponse.items[0].score);
      } else {
        this.sent = true;
        this.invalid = true;
      }
    }

    this.formChangeSub();
    this.loading = false;
  }
}
