import { Injectable } from '@angular/core';
import { TicketService } from './ticket.service';
import { AnnouncementService } from './announcement.service';
import { SmartReportingService } from './smart-reporting.service';
import { AnalyticsService } from './analytics.service';
import { PushNotificationSchema } from '@capacitor/push-notifications';
import { ProjectTabs } from '../enums/project-tabs';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { NavigationExtras, Router } from '@angular/router';
import { ModalController } from '@ionic/angular/standalone';
import { CurrentWhiteLabelApplication } from '../utils/current-white-label-application';
import { AnalyticsType } from '../enums/analytics-type';

@Injectable({ providedIn: 'root' })
export class DeepLinkService {
  public static readonly PUSH_LINK_PREFIX = 'bouwapp';

  constructor(
    private ticketService: TicketService,
    private announcementService: AnnouncementService,
    private analyticsService: AnalyticsService,
    private smartReportingService: SmartReportingService,
    private apiService: ApiService,
    private translateService: TranslateService,
    private router: Router,
    private modalCtrl: ModalController,
    private app: CurrentWhiteLabelApplication
  ) {}

  public async checkLink(link: string, notification?: PushNotificationSchema) {
    let extras: NavigationExtras;
    let match;
    let url = [];

    if (link && link.length > 0) {
      if (await this.modalCtrl.getTop()) {
        this.modalCtrl.dismiss();
      }
    }

    if (
      (match = this.matchLink('projects/([a-z0-9-]+)/rating-monitor', link)) !=
      null
    ) {
      url = ['projects', match[1], 'feedback'];

      this.logNotification('rating-monitor', match[1], notification);
    } else if (
      (match = this.matchLink('projects/([a-z0-9-]+)/updates', link)) != null
    ) {
      url = ['projects', match[1]];
      let updateId = link.split('?id=')[1];
      extras = { state: { tab: ProjectTabs.UPDATES } };

      if (updateId) {
        const params = updateId.split('&');

        if (params[1]) {
          const areaFollowerPart = params.find((part) =>
            part.startsWith('areaFollower')
          );
          const areaFollowerValue = areaFollowerPart.split('=')[1];

          extras.state.areaFollower = areaFollowerValue;
        }

        url = [...url, ...['update', params[0]]];
      }
      this.logNotification('updates', match[1], notification);
    } else if (
      (match = this.matchLink('organizations/([a-z0-9-]+)/updates', link)) !=
      null
    ) {
      url = ['organizations', match[1]];
      let updateId = link.split('?id=')[1];

      if (updateId) {
        url = [...url, ...['update', updateId]];
      }
      this.logNotification('updates', match[1], notification);
    } else if (
      (match = this.matchLink('projects/([a-z0-9-]+)$', link)) != null
    ) {
      if (this.app.isProjectApp()) {
        url = ['project'];
      } else {
        url = ['projects', match[1]];
      }

      this.logNotification('updates', match[1], notification);
    } else if (
      (match = this.matchLink(
        'announcements/([0-9]+)/project/([a-z0-9-]+)',
        link
      )) != null
    ) {
      const announcementId = match[1];
      const projectSlug = match[2];

      const announcement = await this.announcementService.getAnnouncement(
        announcementId
      );
      let parentId = announcement.phase['@id'].split('/');
      url = ['projects', projectSlug, 'phase', parentId[parentId.length - 1]];

      this.logNotification('announcement', match[1], notification);
    } else if ((match = this.matchLink('messages/([0-9]+)', link)) != null) {
      url = ['messages', match[1]];

      this.logNotification('general-message', match[1], notification);
    } else if (
      (match = this.matchLink('projects/([a-z0-9-]+)/surveys', link)) != null
    ) {
      url = ['projects', match[1]];
      url.push('survey');
      let surveyId = link.split('?id=')[1];
      url.push(surveyId);

      this.logNotification('survey', match[1], notification);
    } else if ((match = this.matchLink('tickets/([0-9]+)', link)) != null) {
      const ticketId = match[1];
      const ticket = await this.ticketService.getTicket(ticketId);

      url = ['projects', ticket.project.slug, 'conversations', ticketId];
      extras = { state: { ticket: ticket } };

      this.logNotification('conversation', match[1], notification);
    } else if (
      (match = this.matchLink('smart-reporting-notices/([0-9]+)', link)) != null
    ) {
      const noticeId = match[1];
      const notice = await this.smartReportingService.details(noticeId);
      url = ['projects', notice.project.slug, 'smart-reporting', noticeId];

      this.logNotification('smart-reporting-notice', match[1], notification);
    } else if ((match = this.matchLink('login/*', link))) {
      const extractedData = link.substring(
        link.indexOf('login/') + 'login/'.length
      );
      const decoded = decodeURIComponent(extractedData);

      try {
        const response = await this.apiService
          .rawGet(decoded + '&client=' + environment.clientId, {
            headers: new HttpHeaders(),
          })
          .toPromise();

        const formData = new FormData();
        formData.append('grant_type', 'authorization_code');
        formData.append('code', response['code']);
        formData.append('client_id', environment.clientId);

        const login = await this.apiService.login(formData).toPromise();
        url = ['device', 'loading'];
      } catch (error) {
        //login didnt work
        this.apiService.presentToast(
          this.translateService.instant('page.login.email.error.title'),
          1500,
          'bottom'
        );
      }
      url = ['/'];
    }

    if (extras?.state) extras.state.notification = true;
    else extras = { state: { notification: true } };

    extras.replaceUrl = true;

    await this.router.navigate(url, extras);
  }

  /**
   * Match link and get groups
   * @param {string} regex
   * @param {string} link
   * @returns {RegExpExecArray|null}
   */
  private matchLink(regex: string, link: string): RegExpExecArray | null {
    const exp = new RegExp('^.*?://' + regex, 'i');

    return exp.exec(link);
  }

  private logNotification(
    type: string,
    projectSlug: string,
    notification?: PushNotificationSchema
  ) {
    this.analyticsService.logEvent(AnalyticsType.NOTIFICATION_CLICKED, {
      projectSlug,
      type,
      title: notification ? notification.title : '',
      body: notification ? notification.body : '',
    });
  }
}
