import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Browser } from '@capacitor/browser';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Viewer } from '@photo-sphere-viewer/core';
import { GyroscopePlugin } from '@photo-sphere-viewer/gyroscope-plugin';
import { Attachment } from 'src/app/interfaces/attachment';
import { PdfService } from 'src/app/services/pdf.service';
import { Attachments } from 'src/app/utils/attachments';
import { Observable } from 'rxjs';
import { VideoService } from '../../services/video.service';
import { ModalController, Platform } from '@ionic/angular/standalone';
import { MediaSliderModalComponent } from '../media-slider-modal/media-slider-modal.component';
import { WidgetService } from 'src/app/services/widget.service';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
  selector: 'app-media-slider',
  templateUrl: './media-slider.component.html',
  standalone: true,
  imports: [NgIf, NgFor, NgClass, InlineSVGModule, TranslateModule],
})
export class MediaSliderComponent implements OnInit, OnDestroy {
  @Input() attachments: Attachment[];
  @Input() scrollEvent: Observable<void>;
  @Input() startAtSlide: number = 0;
  @Input() inModal: boolean = false;

  public canAutoplay: boolean = false;
  public isDesktop: boolean = false;
  private currentSlide: number = 0;
  private psvInitialized: boolean = false;
  private psViewers: Viewer[] = [];

  @ViewChildren('photoSphere') photoSpheres: QueryList<ElementRef>;
  @ViewChildren('video') videos: QueryList<ElementRef>;
  @ViewChild('slides') slides: ElementRef;

  @Output() openLink = new EventEmitter<string>();
  @Output() nextSlide = new EventEmitter<number>();

  constructor(
    private translateService: TranslateService,
    private platform: Platform,
    private videoService: VideoService,
    private modalCtrl: ModalController,
    private pdfService: PdfService,
    private widgetService: WidgetService,
    private elem: ElementRef
  ) {}

  ngOnInit() {
    this.isDesktop = this.platform.is('desktop');
    this.videoService.checkVideos$.subscribe(() => {
      this.checkVideos();
    });

    this.attachments.forEach((attachment) => {
      attachment['isPlaying'] = false;
      if (!attachment.mimeType) {
        attachment.mimeType = Attachments.determineMimeType(attachment);
      }
    });
  }

  ngAfterViewInit() {
    if (this.isDesktop && this.slides?.nativeElement !== undefined) {
      this.slides.nativeElement.navigation = true;
    }
    this.initPhotoSpheres();
    this.initVideos();
    if (this.startAtSlide) {
      this.currentSlide = this.startAtSlide;
      this.goToSlide(this.startAtSlide);
    }
  }

  ngOnDestroy() {
    this.psViewers.forEach((v) => v.destroy());
  }

  openPDF(attachment: Attachment) {
    if (this.widgetService.getIsEmbedded()) {
      this.widgetService.openApp();
      return;
    }

    try {
      Browser.open({ url: attachment.filePathThumbnails.full });
    } catch (error) {
      this.pdfService.openInBrowser(attachment.filePathThumbnails.full);
    }
  }

  toggleVideo(index: number, attachment: Attachment) {
    this.videoService.forceStopAll(); //stop all videos when playing a new one
    this.videos.forEach((video) => {
      let videoElement = video.nativeElement;
      if (videoElement.getAttribute('data-index') == index) {
        if (attachment['isPlaying']) videoElement.pause();
        else videoElement.play();
        attachment['isPlaying'] = !attachment['isPlaying'];
      }
    });
  }

  openYoutube(attachment: Attachment) {
    if (this.widgetService.getIsEmbedded()) {
      this.widgetService.openApp();
      return;
    }

    Browser.open({ url: 'https://youtu.be/' + attachment.videoId });
  }

  checkVideos() {
    this.videos.forEach((video) => {
      if (
        !video.nativeElement.paused &&
        (!this.videoService.isElementInViewPort(video.nativeElement) ||
          video.nativeElement.offsetParent == null)
      ) {
        if (
          video.nativeElement.readyState > 2 &&
          video.nativeElement.currentTime > 0 &&
          !video.nativeElement.ended
        ) {
          video.nativeElement.pause();
        } else {
          video.nativeElement.addEventListener('canplay', () => {
            video.nativeElement.pause();
          });
        }
      }
    });
  }

  initVideos() {
    this.videos.forEach((video, index) => {
      let videoElement = video.nativeElement;
      videoElement.addEventListener('pause', () => {
        let _index = 0; //because the index of the videos is different from the index of all attachments
        this.attachments.forEach((attachment) => {
          if (this.isCustomVideo(attachment)) {
            if (_index == index) attachment['isPlaying'] = false;
            _index++;
          }
        });
      });
    });
  }

  initPhotoSpheres() {
    if (!this.photoSpheres.length) {
      this.psvInitialized = true;
      return;
    }

    let observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !this.psvInitialized) {
          this.psvInitialized = true;
          observer.unobserve(this.elem.nativeElement);
          observer.disconnect();
          const psAttachments = this.attachments.filter((a) => a.is360);

          this.photoSpheres.forEach((photoSphere, index) => {
            let viewer = new Viewer({
              container: photoSphere.nativeElement,
              panorama: psAttachments[index]?.filePathThumbnails?.compressed,
              navbar: this.inModal ? 'gyroscope fullscreen' : 'gyroscope',
              loadingTxt: this.translateService.instant('loading'),
              plugins: [GyroscopePlugin],
            });

            viewer.addEventListener('render', () => {
              const plugin = viewer.getPlugin(GyroscopePlugin);
              plugin.init();
            });

            viewer.addEventListener('fullscreen', (enabled) => {
              // Disable sliding Swiper when in PSV fullscreen
              this.slides.nativeElement.swiper.allowTouchMove = !enabled;
            });

            if (!this.inModal) {
              // Prevent click getting intercepted on mobile
              photoSphere.nativeElement.style['pointer-events'] = 'none';
            }
            this.psViewers.push(viewer);
          });
        }
      },
      { threshold: 0.5 }
    );
    observer.observe(this.elem.nativeElement);
  }

  isPdf(attachment: Attachment) {
    return Attachments.isPdf(attachment);
  }

  isImage(attachment: Attachment) {
    if (this.is360(attachment)) return false;
    return Attachments.isImage(attachment);
  }

  isCustomVideo(attachment: Attachment) {
    return Attachments.isCustomVideo(attachment);
  }

  isYoutubeVideo(attachment: Attachment) {
    return Attachments.isYoutubeVideo(attachment);
  }

  is360(attachment: Attachment) {
    return Attachments.is360(attachment);
  }

  isOther(attachment: Attachment) {
    return Attachments.isOther(attachment);
  }

  goToSlide(index: number) {
    this.slides?.nativeElement.swiper.slideTo(index, 0);
  }

  slideChanged(event) {
    this.currentSlide = this.slides?.nativeElement.swiper.activeIndex;
    this.nextSlide.emit(this.currentSlide);
  }

  async openModal(event) {
    if (this.inModal || this.widgetService.getIsEmbedded()) return;

    if (this.isDesktop) {
      if (event.target.tagName === 'SWIPER-CONTAINER') {
        return;
      }
    }
    let slideChanged = new EventEmitter();
    slideChanged.subscribe((index) => this.goToSlide(index));

    const modal = await this.modalCtrl.create({
      component: MediaSliderModalComponent,
      componentProps: {
        attachments: this.attachments,
        startAtSlide: this.currentSlide,
        slideChanged: slideChanged,
      },
      canDismiss: true,
      cssClass: 'media-slider-modal',
    });

    modal.present();
  }
}
